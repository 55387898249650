import config from 'config'

export function trackClick(eventName) {
  if (config.env !== 'production') {
    return
  }

  if (window.gtag) {
    window.gtag('event', eventName)
  }
}
