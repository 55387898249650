import React from 'react'
import Props from 'prop-types'
import { withProps } from 'recompose'
import { StoreBadge } from './styled'
import AppStoreLogo from './assets/app-store-logo-en.svg'
import PlayStoreLogo from './assets/play-store-logo-en.svg'
import { trackClick } from 'common/tracking'

const DownloadButton = ({ children, isGoogle, trackingId }) => (
  <StoreBadge>
    <a
      href="https://zoeapp.app.link/oWbj2DKgQ2"
      target="_blank"
      alt={children}
      rel="noopener noreferrer"
      onClick={() => {
        if (trackingId) {
          trackClick(trackingId)
        }
      }}
    >
      <img
        src={isGoogle ? PlayStoreLogo : AppStoreLogo}
        alt={children}
        width={200}
        height={60}
      />
    </a>
  </StoreBadge>
)

DownloadButton.defaultProps = {
  trackingId: '',
}

DownloadButton.propTypes = {
  children: Props.string.isRequired,
  isGoogle: Props.bool.isRequired,
  trackingId: Props.string,
}

export default withProps(props => ({
  isGoogle: props.type === 'google',
}))(DownloadButton)
